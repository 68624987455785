let FormObserver = function (form) {

    /**
     *
     */
    let _private = {};

    /**
     *
     * @type {string}
     */
    _private.saveButtonSelector = '#global-save-button,.save-button-wrap a';


    _private.elementSelector = ''
        + 'input[type=checkbox],'
        + 'input[type=text],'
        + 'input[type=number],'
        + 'input[type=radio],'
        + 'input[type=password],'
        + 'input[type=hidden],'
        + 'select,'
        + 'textarea';

    _private.discardChangeSelectors = [
        'div.content-header div.edit-menu a.create',
        'div.content-header div.edit-menu a.index',
        '#navigation a:not(.ajax)'
    ];

    /**
     *
     * @type {{}}
     */
    _private.logData = {
        elements:       {},
        updateElements: {},
        hasChange:      false,
        isValid:        true
    };

    /**
     *
     */
    _private.instance = this;

    /**
     *
     * @type {jQuery}
     */
    _private.form = $();

    this.saveButtonClicked = false;


    /**
     *
     * @param {*} form
     * @returns {*|undefined}
     */
    this.init = (form) => {
        _private.form       = $(form);
        _private.formHelper = new EfbHelperForm();
        _private.form.data('FormObserver', this);
        if (!_private.form.is('form')) {
            _private.form = _private.form.find('form');
        }
        if (_private.form.length === 0) {
            return undefined;
        }
        _private.bindEvents();
        this.fillLog();
        this.enableDiscardProtection(true);
        return this;
    };

    this.enableDiscardProtection = (enable = true) => {
        let leafButtons = $(_private.discardChangeSelectors.join(','));
        if (!enable) {
            leafButtons.unbind('click.discardProtection');
            return this;
        }
        leafButtons.unbind('click.discardProtection').on('click.discardProtection', (event, force) => {
            let url    = $(event.currentTarget).attr('href');
            let target = $(event.currentTarget).attr('target');
            if (event.metaKey || event.altKey || event.shiftKey || event.ctrlKey
                || !url || url === '' || url === '#' || target === '_blank' || force
                || !this.getLogData().hasChange) {
                return;
            }
            event.preventDefault();
            this.showDiscardProtectionDialog(() => {location.href = url;});
        });
        return this;
    };

    this.showDiscardProtectionDialog = (discard) => {
        return new EfbHelperDialog({
            isConfirmDialog: true,
            text:            'confirm.discard-changes',
            buttons:         [
                {
                    text:  EfbHelper._('button.cancel'),
                    class: 'button light text',
                    click() {$(this).dialog('close')}
                },
                {
                    text:  EfbHelper._('button.discard'),
                    class: 'button text discard-changes',
                    click() {
                        $(this).dialog('close');
                        discard();
                    }
                },
            ]
        }).show();
    };

    /**
     *
     * @param {*} form
     * @param {boolean} [init]
     * @returns {*|undefined}
     */
    this.getInstanceFromForm = (form, init) => {
        if (!form.is('form')) {
            form = form.find('form');
        }
        let instance = $(form).data('FormObserver');
        if (instance) {
            return instance;
        }
        if (init) {
            return this.init(form);
        }
        return undefined;
    };

    /**
     *
     * @returns {*}
     */
    this.preCheckForms = () => {
        let initialErrors = _private.form.find('div.formelement.initial-error');
        $(initialErrors).removeClass('initial-error');
        return this.getFormErrors();
    };

    /**
     *
     * @returns {boolean}
     */
    this.handleSaveButton = () => {
        _private.logData.isValid = !this.getFormErrors(true, true);
        if (_private.logData.isValid && _private.logData.hasChange) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
        _private.form.trigger('observerEvent', [_private.logData]);
        return _private.logData.isValid && _private.logData.hasChange;
    };

    /**
     *
     * @param {*} elements
     * @returns {boolean}
     */
    this.addElement = (elements) => {
        elements = $(elements);
        let form = $(elements).parents('form');
        if (!form.is(_private.form)) {
            return false;
        }
        if ($.inArray(elements.prop('tagName').toLowerCase(), ['input', 'select', 'textarea']) === -1) {
            elements = elements.find('input,select,textarea');
        }
        let hasInitialError = !this.saveButtonClicked || _private.form.find('.initial-error').length > 0;
        elements.each((i, element) => {
            element = $(element);
            let id  = element.attr('id');
            if (_.isUndefined(id)) {
                return;
            }
            if (element.is('select')) {
                _private.bindSelectEvents(element);
            } else if (element.attr('type') === 'radio') {
                _private.bindRadioEvents(element);
            } else {
                _private.bindInputEvents(element);
            }
            if (_.isUndefined(_private.logData.elements[id])) {
                _private.logData.elements[id] = {
                    isRemoved:    false,
                    isNew:        true,
                    currentValue: element.val(),
                    defaultValue: element.val() + '#####'
                };
            } else {
                _private.logData.elements[id]['currentValue'] = element.val();
                _private.logData.elements[id]['isRemoved']    = false;
            }
            this.checkLog(element);
        });
        if (hasInitialError) {
            elements.parent('div.formelement').addClass('initial-error');
        }
        this.handleSaveButton();
        return true;
    };

    /**
     * Remove some elements from form to trigger a change
     *
     * @param {*} elements
     * @param {boolean} [removeDom] remove
     * @param {boolean} [removeWrap] remove
     */
    this.removeElements = (elements, removeDom, removeWrap) => {
        if (!_.isUndefined($(elements).prop('tagName')) &&
            $.inArray($(elements).prop('tagName').toLowerCase(), ['input', 'select', 'textarea']) === -1) {
            elements = $(elements).find('input,select,textarea');
        }
        $(elements).each((i, element) => {
            this.removeElement($(element), removeDom, removeWrap);
        });
    };

    /**
     * Remove one element from form to trigger a change
     *
     * @param {*} element
     * @param {boolean} [removeDom] remove
     * @param {boolean} [removeWrap] remove
     * @return {boolean}
     */
    this.removeElement = (element, removeDom = true, removeWrap = false) => {
        element    = $(element);
        removeWrap = _.isUndefined(removeWrap) ? removeDom : removeWrap && removeDom;
        if (!element.length || !element.parents('form').is(_private.form)) {
            return false;
        }
        let id = element.attr('id');
        Validator.removeError(element);
        if (!_.isUndefined(_private.logData.elements[id])) {
            if (_private.logData.elements[id]['isNew'] === true) {
                delete _private.logData.elements[id];
                delete _private.logData.updateElements[id];
            } else {
                _private.logData.elements[id]['isRemoved'] = true;
                _private.logData.updateElements[id]        = _private.logData.elements[id];
            }
        }
        if (removeWrap) {
            element.parent('div.formelement').remove();
        } else if (removeDom) {
            element.remove();
        }
        this.checkLog();
        this.handleSaveButton();
        return true;
    };

    /**
     *
     * @param {*} element
     * @param {*} [value] value
     * @returns {boolean}
     */
    this.updateLog = (element, value) => {
        element = $(element);
        if (element.hasClass('inactive') || element.hasClass('noObserve') || _.isUndefined(element.attr('id'))) {
            return false;
        }
        let elementId = element.attr('id');
        if (_.isUndefined(value)) {
            value = element.val();
        }
        if (_.isUndefined(_private.logData.elements[elementId])) {
            _private.logData.elements[elementId] = {
                isNew: true
            };
        }
        _private.logData.elements[elementId]['currentValue'] = value;
        return this.checkLog(element);
    };

    /**
     *
     * @param {*} [inputElement]
     * @returns {boolean}
     */
    this.checkLog = (inputElement) => {
        let formLogs = _private.logData;
        if (!_.isUndefined(inputElement)) {
            let inputId = $(inputElement).attr('id');
            if (_.isUndefined(formLogs.updateElements[inputId])) {
                formLogs.updateElements[inputId] = formLogs.elements[inputId];
            }
        }
        $.each(formLogs.updateElements, (inputKey, element) => {
            if (element.isRemoved) {
                return false;
            }
            let originalElement = _private.form.find('#' + inputKey);
            if (originalElement.length === 0) {
                if (!_.isUndefined(element.isNew) && element.isNew === true) {
                    delete formLogs.updateElements[inputKey];
                }
                return false;
            }
            let inputValue = originalElement.val();
            let inputType  = originalElement.attr('type');
            if (inputType === 'checkbox' || inputType === 'radio') {
                inputValue = originalElement.prop('checked');
            }
            if (element && inputValue === element.defaultValue) {
                delete formLogs.updateElements[inputKey];
            }
            return true;
        });
        _private.logData.hasChange = Object.keys(formLogs.updateElements).length;
        return _private.logData.hasChange;
    };

    /**
     * Fills the logData
     */
    this.fillLog = () => {
        _private.logData = {
            updateElements: {},
            elements:       {}
        };
        _private.form.find(_private.elementSelector).each((i, element) => {
            element = $(element);
            let id  = element.attr('id');
            if (_.isUndefined(id) || element.hasClass('inactive') || element.hasClass('noObserve')) {
                return;
            }
            let value = element.val();
            let type  = element.attr('type');
            if (type === 'checkbox' || type === 'radio') {
                value = element.prop('checked');
            }
            if (_.isUndefined(_private.logData.elements[id])) {
                _private.logData.elements[id] = {
                    isNew:     false,
                    isRemoved: false
                };
            }
            _private.logData.elements[id]['defaultValue'] = value;
            _private.logData.elements[id]['currentValue'] = value;
        });
        _private.logData.hasChange = false;
        _private.logData.isValid   = !this.getFormErrors(true, true);
    };

    /**
     * Get the existing errors in current form
     *
     * @param {boolean} [returnHasErrors]
     * @param {boolean} [noInitErrors]
     * @returns {boolean|Array}
     */
    this.getFormErrors = (returnHasErrors, noInitErrors) => {
        returnHasErrors = _.isUndefined(returnHasErrors) ? false : returnHasErrors;
        noInitErrors    = _.isUndefined(noInitErrors) ? false : noInitErrors;
        let selector    = 'div.formelement.error:not(.hide)';
        if (noInitErrors) {
            selector += ':not(.initial-error)';
        }
        let errors              = _private.form.find(selector);
        let activeErrors        = [];
        _private.logData.errors = [];
        errors.each((i, errorFormelement) => {
            errorFormelement = $(errorFormelement);
            if (errorFormelement.parent('div.inactive').length > 0 ||
                errorFormelement.parents('div.inactive').length > 0) {
                return true;
            }
            if (errorFormelement.hasClass('error')) {
                activeErrors.push(errorFormelement);
            }
            return true;
        });
        _private.logData.errors = activeErrors;
        return returnHasErrors ? activeErrors.length > 0 : activeErrors;
    };

    /**
     *
     */
    _private.bindEvents = () => {
        _private.bindInputEvents(_private.form.find('input[type=text],input[type=number],input[type=password],textarea'));
        _private.bindCheckboxEvents(_private.form.find('input[type=checkbox]'));
        _private.bindRadioEvents(_private.form.find('input[type=radio]'));
        _private.bindSelectEvents(_private.form.find('select,input[type=file]'));
    };

    /**
     *
     * @param {*} elements
     */
    _private.bindInputEvents = (elements) => {
        const bind = event => {
            let element = $(event.target);
            _private.formHelper.formatElementValue(element);
            if (element.hasClass('noObserve')) {
                return;
            }
            Validator.validateElement(event);
            this.updateLog(element);
            this.handleSaveButton();
        }
        $(elements).unbind('blurEnter.bindInputEvents')
                   .on('blurEnter.bindInputEvents', bind)
                   .filter('.observe-keyup')
                   .on('keyup.bindInputEvents', bind)
        ;
    };

    /**
     *
     * @param {*} elements
     */
    _private.bindCheckboxEvents = (elements) => {
        $(elements).unbind('click.bindCheckboxEvents').on('click.bindCheckboxEvents', (event) => {
            let element = $(event.target);
            if (element.hasClass('noObserve')) {
                return;
            }
            let value = element.prop('checked');
            Validator.validateElement(event);
            this.updateLog(element, value);
            this.handleSaveButton();
        });
    };

    /**
     *
     * @param {*} elements
     */
    _private.bindRadioEvents = (elements) => {
        $(elements).unbind('click.bindCheckboxEvents').on('click.bindCheckboxEvents', (event) => {
            let element = $(event.target);
            if (element.hasClass('noObserve')) {
                return;
            }
            let value      = element.prop('checked');
            let otherRadio = element.parents('form')
                                    .find('input[type=radio][name="' + element.attr('name') + '"]')
                                    .not(element);
            otherRadio.each((i, radio) => {
                this.updateLog($(radio), false);
            });
            this.updateLog(element, value);
            Validator.validateElement(event);
            this.handleSaveButton();
        });
    };

    /**
     *
     * @param {*} elements
     */
    _private.bindSelectEvents = (elements) => {
        $(elements).unbind('change.bindSelectEvents').on('change.bindSelectEvents', (event) => {
            let element = $(event.target);
            if (element.hasClass('noObserve')) {
                return;
            }
            Validator.validateElement(event);
            this.updateLog(element);
            this.handleSaveButton();
        });
    };


    /**
     *
     * @param {string} nameSpace
     * @param {function} callBack
     * @return {*}
     */
    this.setSaveCallBack = (nameSpace, callBack) => {
        $(_private.saveButtonSelector).unbind('click').on('click.' + nameSpace, (event) => {
            event.preventDefault();
            this.saveButtonClicked = true;
            if ($(event.currentTarget).hasClass('disabled')) {
                return false;
            }
            $(_private.saveButtonSelector).addClass('disabled');
            return callBack.apply(this, [event, $(event.currentTarget).hasClass('save-with-comment')]);
        });
        return this;
    };


    /**
     *
     * @returns {boolean}
     */
    this.disableSaveButton = () => {
        $(_private.saveButtonSelector).addClass('disabled');
        return true;
    };

    /**
     *
     * @returns {boolean}
     */
    this.enableSaveButton = () => {
        $(_private.saveButtonSelector).removeClass('disabled');
        return true;
    };

    /**
     *
     * @param {string|jQuery|*} saveButtonSelector
     */
    this.setSaveButtonSelector = (saveButtonSelector) => {
        _private.saveButtonSelector = saveButtonSelector;
        return this;
    };

    this.getSaveButtonSelector = () => {
        return _private.saveButtonSelector;
    };

    /**
     *
     * @returns {*}
     */
    this.getLogData = () => {
        return _private.logData;
    };

    /**
     *
     * @param selector
     * @returns {*}
     */
    this.setSaveButton = (selector) => {
        _private.saveButtonSelector = selector;
        return this;
    };


    this.getDiscardChangeSelectors = () => {
        return _private.discardChangeSelectors;
    };

    this.setDiscardChangeSelectors = (selectors) => {
        _private.discardChangeSelectors = selectors;
        return this;
    };


    if (!_.isUndefined(form) && $(form).is('form')) {
        let instance = $(form).data('FormObserver');
        if (instance) {
            Object.assign(this, instance);
        }
        return this;
    }
};